// Types for the result object with discriminated union
type Success<T> = {
  data: T;
  error: null;
};

type Failure<E> = {
  data: null;
  error: E;
};

type Result<T, E = Error> = Success<T> | Failure<E>;

// Main wrapper function
export default async <T, E = Error>(
  promise: Promise<T>
): Promise<Result<T, E>> => {
  try {
    const data = await promise;
    return { data, error: null };
  } catch (error) {
    return { data: null, error: error as E };
  }
};

// Example Usage:
/*
async function doMath() {
  const { data, error } = await tryCatch(somePromise());
  if (error) return {error: "unable to process"}

  const { data: data2, error: error2 } = await tryCatch(someOtherPromise(data));
  if (error2) return {error: "unable to process"}

  data + data2;
}
 */
