import type { BaseCoords } from '@models/BaseCoords';

export default (): Promise<BaseCoords> => {
  return new Promise<BaseCoords>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        resolve({ latitude, longitude });
      },
      (error) => {
        reject(error);
      },
      {
        timeout: 5000,
      }
    );
  });
};
